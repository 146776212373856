import p4_img from './gain_combo.png'
import p5_img from './loss_combo.png'
import p6_img from './combo.png'


let combo_data = [
    {
        id:4,
        key:10,
        name:"Okhli Weight Gain Mix with Stomach Relief Powder",
        desc:"Improves Metabolism and increase hunger",
        image:p4_img,
        new_price:1999.00,
        old_price:3399.00
    },
    {
        id:5,
        key:11,
        name:"Okhli Weight Loss Mix with Stomach Relief Powder",
        desc:"Improves Stamina",
        image:p5_img,
        new_price:1999.00,
        old_price:3399.00
    },
    {
        id:6,
        key:12,
        name:"2 Okhli Weight Gain Mix with Stomach Relief Powder",
        desc:"Improves Metabolism and increase hunger",
        image:p6_img,
        new_price:3499.00,
        old_price:4599.00
    },
];

export default combo_data;