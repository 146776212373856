import React from 'react'
import './Margin.css'

const Contacts = () => {
  return (
    <div className='margin'>
      
    </div>
  )
}

export default Contacts