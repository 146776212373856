import React from "react";

const Menubar = ({toggleSidebar}) => {
    return(
        <div className="menu-icon" onClick={toggleSidebar}>
            &#9776;
        </div>
    )
};

export default Menubar;
