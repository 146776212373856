import React from 'react'
import './Why.css'
import cruel from '../Assets/cruel.png'
import side from '../Assets/side.png'
import vaidyas from '../Assets/vaidyas.png'
import ayurved from '../Assets/ayurved.png'
import cod from '../Assets/cod.png'

const Why = () => {
  return (
    <div className='why'>
        <div className='why-okhli'>
            <h1>WHY  OKHLI  AYURVEDA?</h1>
        </div>
        <div className="why-icon-3">
            <div className="why-icons-container">
                <img src={cruel} alt=''/>
            </div>
            <div className="why-icons-container">
                <img src={vaidyas} alt=''/>
            </div>
            <div className="why-icons-container">
                <img src={ayurved} alt=''/>
            </div>
        </div>
        <div className='why-icon-2'>
            <div className="why-icons-container">
                <img src={cod} alt=''/>
            </div>
            <div className="why-icons-container">
                <img src={side} alt=''/>
            </div>
        </div>
    </div>
  )
}

export default Why