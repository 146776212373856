import Home from '../Pages/Home'
import Cart from '../Pages/Cart'
import Feedback from '../Pages/Feedbacks'
import Product from '../Pages/Product'
import Contact from '../Pages/Contacts'

const routes = [
    {path: '/',component:Home,title:'HOME'},
    {path: '/cart',component:Cart,title:'CART'},
    {path: '/feedback',component:Feedback,title:'FEEDBACK`S'},
    {path: '/products',component:Product,title:'PRODUCTS'},
    {path: '/contacts',component:Contact,title:'CONTACT'}
];

export default routes;