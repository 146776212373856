import React from 'react'
import Review from '../Components/Review/Review'
import reviews from '../Components/Assets/reviewData'
import './Margin.css'

const Feedbacks = () => {
  return (
    <div>
      <div className="margin"></div>
    <Review reviews={reviews} />
    </div>
  )
}

export default Feedbacks