import React, { useContext, useEffect, useRef, useState } from 'react'
import './Navbar.css'
import logo from '../Assets/logo.png'
import cart from '../Assets/cart.png'
import { Link, useLocation } from 'react-router-dom'
import { ShopContext } from '../../context/ShopContext'
import routes from '../routes'
import Menubar from '../MenuBar/Menubar'
import Sidebar from '../Sidebar/Sidebar'

export const Navbar = () => {
    const [menu,setMenu] = useState("home");
    const {getTotalCartItems} = useContext(ShopContext);
    const[isSidebarOpen,setIsSidebaropen] = useState(false);
    const[title,setTitle] = useState('');
    const location = useLocation();

    const toggleSidebar = () => {
        console.log("in Toggle");
        setIsSidebaropen(!isSidebarOpen);
    }

    const closeSidebar = (event) => {
        if(!event.target.closest('.sidebar') && !event.target.closest('.menu-icon')){
            setIsSidebaropen(false);
        }
    }
    useEffect(() => {
        const currentRoute = routes.find(route => route.path === location.pathname);
        if(currentRoute){
            setTitle(currentRoute.title);
        }else{
            setTitle('Default Title');
        }

        document.addEventListener('click',closeSidebar);
        return () => {
            document.removeEventListener('click',closeSidebar);
        }
    },[location]);

  return (
    <div className='navbar'>
        <div className="offerSection">
            <p>Buy now to get 33% off on MRP and free COD</p>
        </div>
        <div className='bar'>
            <div className="menu-icon">
                <Menubar toggleSidebar={toggleSidebar}/>
                <Sidebar isOpen={isSidebarOpen}/>
            </div>
            <div className='nav-logo'>
                <img src={logo} id='logoImg' alt=''/>
                <p>OKHLI</p>
            </div>
            <div className='nav-login-cart'>
                <Link style={{textDecoration:'none'}} to='/cart'>
                <img src={cart} id="cartLogo" alt=''/></Link> 
                <div className="nav-cart-count">{getTotalCartItems()}</div>
            </div>
        </div>
    </div>
  )
}
