import React from 'react'
import CartItem from '../Components/CartItem/CartItem'
import './Margin.css'
import Address from '../Components/AddressForm/Address'

const Cart = () => {
  return (
    <div className='cart'>
      <CartItem/>
      <Address/>
    </div>
  )
}

export default Cart