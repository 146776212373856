import React from 'react'
import './Footer.css'
import footer_logo from '../Assets/logo.png'
import insta_icon from '../Assets/insta.png'
import whatsapp_icon from '../Assets/whatsup.png'
import facebook_icon from '../Assets/facebook.png'

const Footer = () => {
  return (
    <div className='footer'>
        <div className="footer-logo">
            <img src={footer_logo} alt=''/>
            <p>OKHLI AYURVEDA</p>
        </div>
        <ul className="footer-links">
            <li>Company</li>
            <li>Products</li>
            <li>About</li>
            <li>Contact</li>
        </ul>
        <div className="footer-social-icon">
            <div className="footer-icons-container">
                <a href="https://www.instagram.com/okhli_ayurveda?igsh=MWxxank3YWM1ZTA3eA==" target="_blank" rel="noopener noreferrer">
                <img src={insta_icon} alt=''/>
                </a>
            </div>
            <div className="footer-icons-container">
            <a href="https://wa.me/+918375064071?text=Hi" target="_blank" rel="noopener noreferrer">
                <img src={whatsapp_icon} alt=''/>
                </a>
            </div>
            <div className="footer-icons-container">
            <a href="https://www.facebook.com/profile.php?id=61561529170343" target="_blank" rel="noopener noreferrer">
                <img src={facebook_icon} alt=''/>
                </a>
            </div>
        </div>
        <div className="footer-copyright">
            <hr />
            <p>Copyright @ 2023 - All Rights Reserved</p>
        </div>
    </div>
  )
}

export default Footer