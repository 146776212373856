import React from 'react'
import './NewsLetter.css'

const Newsletter = () => {
  return (
    <div className='newsletter'>
      <div className='news-heading'>
        <h1>Get Exclusive Offers On Your Email</h1>
        <p>Subscribe to our newsletter and stay updated</p>
      </div>
      <div className='email-field'>
            <input type="email" placeholder='Your Email ID'/>
            <button>Subscribe</button>
        </div>
    </div>
  )
}

export default Newsletter