import React from 'react'
import Slider from 'react-slick';
import './Topslider.css'
import im1 from '../Assets/img1.png'
import im2 from '../Assets/img2.png'
import im3 from '../Assets/img3.png'

const Topslider = () => {

  const images = [
    im1,
    im2,
    im3
  ];

  const settings = {
    dots:true,
    infinite:true,
    speed:500,
    slidesToShow:1,
    slidesToScroll:1,
    autoplay:true,
    autoplaySpeed:3000,
    pauseOnHover:true,
    swipe:true,
    responsive:[
        {
            breakpoint:1024,
            setting:{
                slidesToShow:1,
                slidesToScroll:1,
            },
        },
        {
            breakpoint:600,
            settings:{
                slidesToShow:1,
                slidesToScroll:1,
            }
        },
        {
            breakpoint:480,
            settings:{
                slidesToShow:1,
                slidesToScroll:1,
            },
        },
    ],
  };

  return (
    <div className='carousel-container'>
        <Slider {...settings}>
            {images.map((image,index) => (
                <div key={index}>
                    <img src={image} alt={`Slide ${index + 1}`} className='carousel-image' />
                </div>
            ))}
        </Slider>
    </div>
  )
}

export default Topslider