import React from 'react'
import Front from '../Components/Front/Front'
import AllProducts from '../Components/AllProducts/AllProducts'
import Newsletter from '../Components/Newsletter/Newsletter'
import Why from '../Components/Why/Why'
import Combo from '../Components/Combos/Combo'
import Points from '../Components/Points/Points'
import Review from '../Components/Review/Review'
import reviews from '../Components/Assets/reviewData'
import './home.css'
import Topslider from '../Components/TopSlider/Topslider'

const Home = () => {
  
  return (
    <div>
      <div className='margin-home'>
      </div>
        <Front/>
        <Topslider />
        <Combo/>
        <Points/>
        <AllProducts/>
        <Why/>
        <Review reviews={reviews} />
        <Newsletter/>
    </div>
  )
}

export default Home