import React from 'react'
import './Points.css'
import best from '../Assets/best.png'
import eco from '../Assets/eco.png'
import health from '../Assets/healthy.png'
import pure from '../Assets/pure.png'

const Points = () => {
  return (
    <div className='points'>
        <div className='point-heading'>
            <h1>OKHLI AYURVEDA - AN ETHNIC STOP FOR YOUR AYURVEDIC NEEDS.</h1>
        </div>
    <div className='points-container'>
        <div className='points-content'>
            <div>
                <img src={best} alt=''/>
                <p id='pHead'>BEST IN MARKET</p>
                <p>10K+ repeating customers.</p>
                <p>Highly valuable products in market.</p>
            </div>
            <div className='sec-point'>
                <img src={eco} alt=''/>
                <p id='pHead'>ECO FRIENDLY</p>
                <p>Cruelty free.</p>
                <p>No animals are harmed.</p>
            </div>
        </div>
        <div className='points-content-sec'>
            <div>
                <img src={pure} alt=''/>
                <p id='pHead'>PURE INGREDIENTS</p>
                <p>100% Pure Ingredients. </p>
                <p>Derived from natural plants.</p>
            </div>
            <div className='sec-point'>
                <img src={health} alt=''/>
                <p id='pHead'>HEALTHY</p>
                <p>Null Side effects.</p>
                <p>More effective products.</p>
            </div>
        </div>
     </div>
    </div>
  )
}

export default Points