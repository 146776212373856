import React from 'react'
import AllProducts from '../Components/AllProducts/AllProducts'
import Combo from '../Components/Combos/Combo'
import './Margin.css'

const Product = ({handleClick}) => {
  return (
    <div>
      <div className='margin'></div>
      <Combo/>
      <AllProducts/>
    </div>
    
  )
}

export default Product