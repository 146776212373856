import './App.css';
import { Navbar } from './Components/Navbar/Navbar';
import { BrowserRouter,Routes,Route} from 'react-router-dom';
import Footer from './Components/Footer/Footer'; 
import routes from './Components/routes';
import Offer from './Components/Offer/Offer';
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

function App() {

  return (
    <div className='App'>
      <BrowserRouter>
      <Navbar/>
      <Routes>
        {routes.map((route,index) => (
          <Route
            key = {index}
            path= {route.path}
            exact
            Component={route.component}
          />
        ))}
      </Routes>
      <Footer/>
      <Offer/>
      </BrowserRouter>
    </div>
  );
}

export default App;
