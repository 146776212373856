import React from "react";
import './Sidebar.css'
import { Link } from "react-router-dom";


const Sidebar = ({isOpen , toggleSidebar}) => {
    return(
        <div className= {`sidebar ${isOpen ? 'visible' : ''}`}>
            <Link to = "/" className="sidebar-item">Home</Link>
            <Link to = "/products" className="sidebar-item">Products</Link>
            <Link to = "/feedback" className="sidebar-item">Feedback's</Link>
            <Link to = "/cart" className="sidebar-item">Cart</Link>
            <Link to = "/contacts" className="sidebar-item">Contact Us</Link>
        </div>
    )
};

export default Sidebar;