import p1_img from './product_1.png'
import p2_img from './product_2.png'
import p3_img from './product_3.png'


let data_product = [
    {
        id:1,
        key:7,
        name:"Okhli Weight Gain Mix",
        desc:"Improves Metabolism and increase hunger",
        image:p1_img,
        new_price:1949.00,
        old_price:2999.00
    },
    {
        id:2,
        key:8,
        name:"Okhli Weight Loss Mix",
        desc:"Improves Stamina",
        image:p2_img,
        new_price:1949.00,
        old_price:2999.00
    },
    {
        id:3,
        key:9,
        name:"Stomach Relief",
        desc:"Improves Digestion",
        image:p3_img,
        new_price:299.00,
        old_price:399.00
    },
];

export default data_product;