import React from 'react'
import './Front.css'
import hand_icon from '../Assets/free.png'
import gain_mix from '../Assets/gain_mix.png'

const Front = () => {
  return (
    <div className='front'>
     <div className="front-left">
        <div className='content'>
            <div>
                <p>Buy</p>
            </div>
            <div>
                <p>Weight Gain Mix</p>
            </div>
            <div className="hand-icon">
                <p>Get</p>
                <img src={hand_icon} alt=''/>
            </div>
            <div>
                <p>Stomach Relief</p>
            </div>
            <div>
            <p>In Only ₹1999</p>
            </div>
            <div>
            <p>33% Off</p>
            </div>
        </div>
     </div>

        <div className="front-right">
            <img src={gain_mix} alt=''/>
        </div>
    </div>
  )
}

export default Front