import React, { useContext, useState } from 'react';
import './Address.css';
import axios from 'axios';
import { ShopContext } from '../../context/ShopContext';
import Loader from '../Loader/Loader';
import Popup from '../Popup/Popup';


const Address = () => {
  const {all_product,cartItems} = useContext(ShopContext);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [popupTitle, setPopupTitle] = useState("");

  const handleClosePopup = () => {
    setIsPopupOpen(false);
    window.location.reload();
  };

  const [loading,setLoading] = useState(false);
  const [buttonDisabled, setButtonDisabled] = useState(false);

  const [formData, setFormData] = useState({
    fullName:'',
    fullAddress: '',
    pinCode: '',
    mobileNumber: '',
    email: '',
    pr_id:'',
  });

  const getPrId = () =>{
    for(let i = 1;i<=6;i++){
      if(cartItems[i] > 0){
        return i;
      }
    }
  }

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleSubmit = async(e) => {
    setButtonDisabled(true);
    e.preventDefault();
    formData.pr_id =  await getPrId();
    if(formData.pr_id == null){
      setPopupTitle("NO ITEMS IN CART");
      setIsPopupOpen(true);
      return;
    }
    setLoading(true);
    try {
      const response = await axios.post('https://okhli-ayurveda-server.vercel.app/sendEmail', {
        fullName: formData.fullName,
        fullAddress: formData.fullAddress,
        pinCode: formData.pinCode,
        mobileNumber: formData.mobileNumber,
        email: formData.email,
        pr_id:formData.pr_id,
      });
      setLoading(false);
      setButtonDisabled(false);
      setPopupTitle("ORDER PLACED!\n CHECK YOUR EMAIL");
      setIsPopupOpen(true);
    }catch(error){
      console.error('Error: ',error);
      setLoading(false);
      alert('Internet Issue !');
    }
  };

  return (
    <form className="address-form" onSubmit={handleSubmit}>
      <div className="form-group">
        <label htmlFor="fullName">Full Name</label>
        <input
          id="fullName"
          name="fullName"
          value={formData.fullName}
          onChange={handleChange}
          required
        />
      </div>
      <div className="form-group">
        <label htmlFor="fullAddress">Full Address</label>
        <textarea
          id="fullAddress"
          name="fullAddress"
          value={formData.fullAddress}
          onChange={handleChange}
          required
        />
      </div>
      <div className="form-group">
        <label htmlFor="pinCode">Pin Code</label>
        <input
          type="text"
          id="pinCode"
          name="pinCode"
          value={formData.pinCode}
          onChange={handleChange}
          required
        />
      </div>
      <div className="form-group">
        <label htmlFor="mobileNumber">Mobile Number</label>
        <input
          type="tel"
          id="mobileNumber"
          name="mobileNumber"
          value={formData.mobileNumber}
          onChange={handleChange}
          required
        />
      </div>
      <div className="form-group">
        <label htmlFor="email">Gmail</label>
        <input
          type="email"
          id="email"
          name="email"
          value={formData.email}
          onChange={handleChange}
          required
        />
      </div>
      {loading &&  <Loader />}
      <button disabled={buttonDisabled} type="submit" className="order-now-button">Order Now</button>
      <Popup
                isOpen={isPopupOpen}
                onClose={handleClosePopup}
                title={popupTitle}
              />
    </form>
  );
};

export default Address;





