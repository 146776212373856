import React from 'react'
import './Combo.css'
import combo_data from '../Assets/comboData'
import Item from '../Item/Item'

const Combo = () => {
  return (
    <div className='combo'>
      <div className="head">
        <h1>COMBO OFFERS</h1>
        <hr />
      </div>
        <div className="products-item">
            {combo_data.map((item)=>{
                return <Item key={item.key} id={item.id} name={item.name} img={item.image} new_price={item.new_price} old_price={item.old_price} desc={item.desc}/>
            })}
        </div>
    </div>
  )
}
    
export default Combo