import React from 'react'
import './Offer.css'

const Offer = () => {
  return (
    <div className='bottom-offer'>
        <p>For any query : Call - 8375064071 </p>
    </div>
  );
}

export default Offer;