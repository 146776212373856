import React from 'react';
import './Loader.css'; // You can style the loader as needed

function Loader() {
  return <div className="loader_cont">
    <div className='loader'>

    </div>
    <p>Order in Progress!</p>
    </div>
}

export default Loader;